import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

//#region Material Components
import { withStyles } from '@material-ui/styles';
import MuiTypography from '@material-ui/core/Typography';
import MuiDivider from '@material-ui/core/Divider';
import MuiGrid from '@material-ui/core/Grid';
import MuiTextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
//#endregion

//#region Internal
import { Components, FontSize } from './../assets/Dimensions';
import { Primary } from './../assets/Colors';
import { Text } from './../assets/Strings';

import PageHeader from './../components/Base/PageHeader';
import SideBar from './../components/SideBar'
import Toolbar from './../components/Toolbar';
import { Notification, error, success } from './../components/Notification/index';

import { updateUserDetail, getUserDetail } from './../api/UserDetail';
import { updateUser } from './../redux/actions/userAction';
import { setSelectedItem } from './../redux/actions/sidebarAction';
import { setLinks } from './../redux/actions/pageHeaderLinkAction';
//#endregion

//#region Override Style
const Divider = withStyles({
    root: {
        marginTop: 10
    }
})(MuiDivider);

const Label = withStyles({
    body1: {
        fontSize: FontSize.Small,
        fontWeight: 'bold'
    }
})(MuiTypography);

const Grid = withStyles({
    container:{
        marginTop: 20
    }
})(MuiGrid);

const EditableTitle = withStyles({
    root: {
        float: 'left'
    },
    body1: {
        fontWeight: 'bold'
    }
})(MuiTypography);

const Title = withStyles({
    body1: {
        fontWeight: 'bold'
    }
})(MuiTypography);

const TextField = withStyles({
    root: {
        '& .MuiInput-underline.Mui-disabled':{
            '&:before':{
                borderBottomStyle: 'none'
            }
        }
    }
})(MuiTextField);
//#endregion

//#region Inline Style
const styles = {
    container: {
        marginLeft: Components.SideBarWidth + 15,
        paddingTop: 20,
        marginRight: 30
    },
    personalInformation: {
        backgroundColor: Primary.ContainerBackground,
        boxShadow: Primary.PanelBoxShadow,
        padding: 20
    },
    questionInformation: {
        marginTop: 20,
        backgroundColor: Primary.ContainerBackground,
        boxShadow: Primary.PanelBoxShadow,
        padding: 20
    },
    moneyInformation: {
        backgroundColor: Primary.ContainerBackground,
        boxShadow: Primary.PanelBoxShadow,
        marginTop: 20,
        marginLeft: 40,
        padding: 20,
    },
    paymentInformation: {
        backgroundColor: Primary.ContainerBackground,
        boxShadow: Primary.PanelBoxShadow,
        marginTop: 40,
        padding: 20
    },
    toolbar: {
        marginBottom: 20
    },
    editIcon: {
        width: Components.Image.Big.Width,
        height: Components.Image.Big.Height
    },
    iconButton: {
        marginLeft: 10,
        padding: 0
    }
}
//#endregion

class UserProfilePage extends Component {
    constructor(props){
        super(props);

        this._user= JSON.parse(localStorage.getItem('user'));

        this.state={
            personalInformationEdit: true,
            questionInformationEdit: true,
            moneyInformationEdit: true,
            paymentInformationEdit: true
        };
    };

    componentWillMount = async () => {
        if(this._user === null){
            this.props.history.push('/login');
            return;
        }
        this.props.setSelectedSideBar('');

        let res = await getUserDetail({UserId: this._user.Id}, this._user.Token);
        if(res.status === 200){
            let user = {
                Id: this._user.Id,
                Name: res.data.Name,
                Surname: res.data.Surname,
                UserName: res.data.UserName,
                Email: res.data.Email,
                PhoneNumber: res.data.PhoneNumber,
                Iban: res.data.Iban,
                DeservedAmount: res.data.DeservedAmount,
                PaidAmount: res.data.PaidAmount,
                Role: res.data.Role,
                Token: this._user.Token,
                TotalEnteredQuestion: res.data.TotalEnteredQuestion,
                SubCourseTopicSelectionCount: res.data.SubCourseTopicSelectionCount,
                AchievementSelectionCount: res.data.AchievementSelectionCount,
                TotalApprovedQuestion: res.data.TotalApprovedQuestion,
                TCKN: res.data.TCKN
            };

            this._user = user;
            localStorage.setItem('user', JSON.stringify(user));
            this.props.setUser(user);
        } else {
            error(res, 2000);    
        }

        this.props.setLinks([{
            Text: Text.UserProfile,
            href: '/userprofile'
        }]);
    };

    _personalInformationEdit = () => {
        this.setState({
            personalInformationEdit: !this.state.personalInformationEdit
        })
    };

    _questionInformationEdit = () => {
        this.setState({
            questionInformationEdit: !this.state.questionInformationEdit
        })
    };

    _moneyInformationEdit = () => {
        this.setState({
            moneyInformationEdit: !this.state.moneyInformationEdit
        })
    };

    _paymentInformationEdit = () => {
        this.setState({
            paymentInformationEdit: !this.state.paymentInformationEdit
        })
    };

    _saveClick = async () => {
        let userProfile = {
            UserId: this._user.Id,
            Name: this._user.Name,
            Surname: this._user.Surname,
            Email: document.getElementById('email').value,
            PhoneNumber: document.getElementById('phoneNumber').value,
            Iban: document.getElementById('iban').value,
            TCKN: document.getElementById('tckn').value,
            AccountStatus: true
        };
        let response = await updateUserDetail(userProfile, this._user.Token);
        if(response.status === 200){
            let res = await getUserDetail({UserId: this._user.Id}, this._user.Token);
            if(res.status === 200){
                let user = {
                    Id: this._user.Id,
                    Name: res.data.Name,
                    Surname: res.data.Surname,  
                    Email: res.data.Email,
                    PhoneNumber: res.data.PhoneNumber,
                    Iban: res.data.Iban,
                    DeservedAmount: res.data.DeservedAmount,
                    PaidAmount: res.data.PaidAmount,
                    Role: res.data.Role,
                    Token: this._user.Token,
                    TCKN: res.data.TCKN,
                    UserName: res.data.UserName
                };
    
                localStorage.setItem('user', JSON.stringify(user));
                this.props.setUser(user);
            } else {
                error(res, 2000);    
            }
            success(Text.SuccessUpdateProfile, 2000);
        } else {
            error(response, 2000);
        }
    };

    render(){
        return(
            <div>   
                <SideBar/>

                <div style={{...styles.container}}>
                    <PageHeader
                        title={Text.UserProfile}
                    />
                    <Toolbar saveClick={this._saveClick}/>
                    <div style={{...styles.personalInformation}}>
                        <div>
                            <EditableTitle>
                                {Text.PersonalInformation}
                            </EditableTitle>
                            <IconButton style={{...styles.iconButton}} onClick={this._personalInformationEdit}>
                                <EditIcon style={{...styles.editIcon}}/>
                            </IconButton>
                        </div>

                        <Divider/>

                        <Grid container>
                            <Grid item xs={3}>
                                <Label>
                                    {Text.NameAndSurname}
                                </Label>

                               <TextField
                                    disabled={true}
                                    defaultValue={(this._user !== null ? this._user.Name : '') + ' ' + (this._user !== null ? this._user.Surname : '')}
                                    InputProps={{ 
                                        style: { fontSize: FontSize.Mid },
                                    }}
                                    style={{
                                        width: '-webkit-fill-available'
                                    }}
                                />
                                
                            </Grid>    

                            <Grid item xs={3}>
                                <Label>
                                    {Text.PhoneNumber}
                                </Label>

                                <TextField
                                    id={'phoneNumber'}
                                    disabled={this.state.personalInformationEdit}
                                    defaultValue={this._user !== null ? this._user.PhoneNumber : ''}
                                    InputProps={{ 
                                        style: { fontSize: FontSize.Mid },
                                    }}
                                />
                            </Grid>    

                            <Grid item xs={3}>
                                <Label>
                                    {Text.Email}
                                </Label>

                                <TextField
                                    id={'email'}
                                    disabled={this.state.personalInformationEdit}
                                    defaultValue={this._user !== null ? this._user.Email : ''}
                                    InputProps={{ 
                                        style: { fontSize: FontSize.Mid },
                                    }}
                                />
                            </Grid>   
                        </Grid>
                    </div>

                    <div>
                        <Grid container>
                            <Grid item style={{...styles.questionInformation}} xs={4}>
                                <div>
                                    <Title>
                                        {Text.QuestionInformation}
                                    </Title>  
                                </div>
                                

                                <Divider/>

                                <Grid container>
                                    <Grid item xs={6}>
                                        <Label>
                                            {Text.TotalEnteredQuestion}
                                        </Label>

                                        <TextField
                                            disabled={this.state.questionInformationEdit}
                                            defaultValue={this._user.Role === 'ContentEditors' ? (this._user !== null ? this._user.TotalEnteredQuestion : '') : 'N.A.'}
                                            InputProps={{ 
                                                style: { fontSize: FontSize.Mid },
                                            }}
                                        />
                                    </Grid>    

                                    <Grid item xs={6}>
                                        <Label>
                                            {Text.TotalApprovedQuestion}
                                        </Label>

                                        <TextField
                                            disabled={this.state.questionInformationEdit}
                                            defaultValue={this._user.Role === 'ContentApprovers' ? (this._user !== null ? this._user.TotalApprovedQuestion : '') : 'N.A.'}
                                            InputProps={{ 
                                                style: { fontSize: FontSize.Mid },
                                            }}
                                        />
                                    </Grid>      
                                </Grid>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Label>
                                            {Text.SubCourseTopicSelectionCount}
                                        </Label>

                                        <TextField
                                            disabled={this.state.questionInformationEdit}
                                            defaultValue={this._user.Role === 'ContentEditors' ? (this._user !== null ? this._user.SubCourseTopicSelectionCount : '') : 'N.A.'}
                                            InputProps={{ 
                                                style: { fontSize: FontSize.Mid },
                                            }}
                                        />
                                    </Grid>    

                                    <Grid item xs={6}>
                                        <Label>
                                            {Text.AchievementSelectionCount}
                                        </Label>

                                        <TextField
                                            disabled={this.state.questionInformationEdit}
                                            defaultValue={this._user.Role === 'ContentApprovers' ? (this._user !== null ? this._user.AchievementSelectionCount : '') : 'N.A.'}
                                            InputProps={{ 
                                                style: { fontSize: FontSize.Mid },
                                            }}
                                        />
                                    </Grid>      
                                </Grid>
                            </Grid>

                            <Grid item style={{...styles.moneyInformation}} xs={6}>
                                <div>
                                    <Title>
                                        {Text.MoneyInformation}
                                    </Title>    
                                </div>
                                
                                <Divider/>

                                <Grid container>
                                    <Grid item xs={4}>
                                        <Label>
                                            {Text.TotalServiceFee}
                                        </Label>

                                        <TextField
                                            disabled={this.state.moneyInformationEdit}
                                            defaultValue={(this._user !== null ? this._user.DeservedAmount : '' || 0) + ' TL'}
                                            InputProps={{ 
                                                style: { fontSize: FontSize.Mid },
                                            }}
                                        />
                                        
                                    </Grid>    

                                    <Grid item xs={4}>
                                        <Label>
                                            {Text.PaidServiceFee}
                                        </Label>

                                        <TextField
                                            disabled={this.state.moneyInformationEdit}
                                            defaultValue={(this._user !== null ? this._user.PaidAmount : '' || 0) + ' TL'}
                                            InputProps={{ 
                                                style: { fontSize: FontSize.Mid },
                                            }}
                                        />
                                    </Grid>   

                                    <Grid item xs={4}>
                                        <Label>
                                            {Text.ServiceFee}
                                        </Label>

                                        <TextField
                                            disabled={this.state.moneyInformationEdit}
                                            defaultValue={((this._user !== null ? this._user.DeservedAmount : 0) - (this._user !== null ? this._user.PaidAmount : 0) || 0) + ' TL'}
                                            InputProps={{ 
                                                style: { fontSize: FontSize.Mid },
                                            }}
                                        />
                                    </Grid>   
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>

                    <div style={{...styles.paymentInformation}}>
                        <div>
                            <EditableTitle>
                                {Text.PaymentInformation}
                            </EditableTitle>
                            
                            <IconButton style={{...styles.iconButton}} onClick={this._paymentInformationEdit}>
                                <EditIcon style={{...styles.editIcon}}/>
                            </IconButton>
                        </div>

                        <Divider/>

                        <Grid container>
                            <Grid item xs={3} style={{
                                marginRight: 30
                            }}>
                                <Label>
                                    {Text.NameAndSurnameWithCorporateName}
                                </Label>

                                <TextField
                                    disabled={this.state.paymentInformationEdit}
                                    defaultValue={(this._user !== null ? this._user.Name : '') + ' ' + (this._user !== null ? this._user.Surname : '')}
                                    InputProps={{ 
                                        style: { fontSize: FontSize.Mid },
                                    }}
                                    style={{
                                        width: '-webkit-fill-available'
                                    }}
                                />
                                
                            </Grid>    

                            <Grid item xs={3} style={{
                                marginRight: 30
                            }}>
                                <Label>
                                    {Text.Iban}
                                </Label>

                                <TextField
                                    id={'iban'}
                                    disabled={this.state.paymentInformationEdit}
                                    defaultValue={this._user !== null ? this._user.Iban : ''}
                                    InputProps={{ 
                                        style: { fontSize: FontSize.Mid },
                                    }}
                                    style={{
                                        width: '-webkit-fill-available'
                                    }}
                                />
                            </Grid>      

                            <Grid item xs={3}>
                                <Label>
                                    {Text.Tckn}
                                </Label>

                                <TextField
                                    id={'tckn'}
                                    disabled={this.state.paymentInformationEdit}
                                    defaultValue={this._user !== null ? this._user.TCKN : ''}
                                    InputProps={{ 
                                        style: { fontSize: FontSize.Mid },
                                    }}
                                    style={{
                                        width: '-webkit-fill-available'
                                    }}
                                />
                            </Grid>  
                        </Grid>
                    </div>

                </div>
                <Notification/>
            </div>
        )
    }
};

UserProfilePage.propTypes = {
    User: PropTypes.object
};

UserProfilePage.defaultProps = {
};

const mapStateToProps = (state) => ({
    User: state.userReducer.User,
    Links: state.pageHeaderLinkReducer.Links
});

const mapDispatchToProps = (dispatch) => ({
    setUser: (user) => dispatch(updateUser(user)),
    setSelectedSideBar: (item) => dispatch(setSelectedItem(item)),
    setLinks: (links) => dispatch(setLinks(links))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserProfilePage));